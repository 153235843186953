import {DatePipe} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {ApiSdkService} from "../../core/api-sdk/api-sdk.service";
import {ContentHeaderService} from "../../core/content-header.service";
import {DatatableOption} from "../../shared/components/datatable/datatable.interface";
import {HeaderService} from "../../shared/components/header/header.service";
import {ConstantLabelPipe} from "../../shared/pipes/constant-label.pipe";


@Component({
    selector:    'app-notification',
    templateUrl: './notification.component.html',
    styleUrls:   ['./notification.component.scss'],
    providers:   [DatePipe, ConstantLabelPipe]
})
export class NotificationComponent implements OnInit {
    datatableOptions: DatatableOption;

    constructor(protected ch: ContentHeaderService,
                protected api: ApiSdkService,
                protected router: Router,
                protected date: DatePipe,
                protected constantLabel: ConstantLabelPipe,
                protected header: HeaderService) {
    }

    ngOnInit() {
        this.ch.setPageTitle('Notification');
        this.ch.setBreadcrumb([]);
        this.initDatatable();

        setTimeout(() => this.header.clearNotification(), 1000);
    }

    initDatatable() {
        this.datatableOptions = {
            defaultPageSize: 100,
            columns:         [
                {
                    title:   'Date',
                    dataKey: obj => this.date.transform(obj.created_at, 'medium'),
                    width:   '20%'
                },
                {
                    title:   'Notification',
                    dataKey: obj => this.constantLabel.transform(obj.extra.status, 'probe.probe_status')
                        .pipe(map(item => `${obj.extra.probe_name} has an alert "${item || '-'}"`)),
                    width:   '100%'
                },
            ],
            loadData:        qp => {
                let queryParam = _.merge(qp, {kind: 'probe-status-web', mark_read: true});
                return this.api.notification.list(queryParam)
            },
            actions:     {
                view:   false,
                edit:   false,
                custom: [
                    {
                        title:     'View',
                        iconClass: 'mdi mdi-chart-line mdi-24px',
                        action:    item => {
                            this.router.navigate([
                                '/a/probes',
                                item.extra.probe_id,
                                'sensors-chart'
                            ])
                        }
                    }
                ]
            },
            search:      false,
            showAddLink: false
        }
    }

}
